import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import { useNavigate } from '@reach/router';
import { Trans, useTranslation } from 'react-i18next';
import config from '../../../shop/config/config.json';
import { getOfferId, getRetailerId } from '../../../utils/catalinaRequests';
import Loading from '../../components/Loading/Loading';
import { gameRedirect } from '../../../utils/utils';
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import './assets/styles.scss';
import {
  isEcommerceMode,
  enableEmail,
  enableName,
  isCollectEnabled,
  landingNoOptin,
  hasHomeSpotlight,
  isModeInStore,
} from '../../../utils/clientConfig';
import { areAllPlayed } from '../../../utils/utils';
import { LandingChecks } from './LandingChecks';
import SpotlightPopin from '../../components/Modal/Spotlight';
import { initializeAppAndGetToken } from '../../../utils/init';

function Landing({ loaded, offerContenu }) {
  const [t] = useTranslation('message');
  const navigate = useNavigate();
  const [playEnabled, setPlayEnabled] = useState(
    landingNoOptin || isModeInStore(),
  );
  const CGULink = offerContenu.CGU;
  const offerId = getOfferId();

  useEffect(() => {
    initializeAppAndGetToken();
    if (areAllPlayed()) {
      navigate('/can-not-play');
    } else {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location.pathname + window.location.search,
          title: 'landingpage',
          offer: offerId,
          retailer: getRetailerId(),
          canal: 'e-commerce',
        },
      });
    }
  }, []);

  async function navigateToPage() {
    if (isCollectEnabled) {
      navigate('/collect-info');
    } else if (isEcommerceMode()) {
      if (enableEmail() || enableName()) {
        navigate('/collect-info');
      } else {
        navigate(gameRedirect(config.gameType));
      }
    } else {
      navigate('/in-store');
    }
  }

  function LandingContent() {
    return loaded ? (
      <div className="info-part">
        <div className="txt-date">
          {t('landing.gamePeriod', {
            start: offerContenu.displayDate || offerContenu.startDate,
            end: offerContenu.endDate,
          })}
        </div>
        <div className="title">
          {offerContenu.offerTitle || t('landing.title')}
        </div>
        <div className="description">
          {offerContenu.offerDescription || t('landing.description')}
        </div>

        {landingNoOptin || isModeInStore() ? (
          <p className="check-no-landing">
            <Trans
              t={t}
              i18nKey="landing.landingNoOptinText"
              components={[
                CGULink ? (
                  <a href={CGULink} target="_blank" rel="noreferrer">
                    Learn more
                  </a>
                ) : (
                  <></>
                ),
              ]}
            />
          </p>
        ) : (
          <LandingChecks
            hasSecondOptin={!config.disableSecondOptin}
            onCheckChange={setPlayEnabled}
            CGULink={CGULink}
          />
        )}

        <div className="button-area">
          <Button
            text={t('landing.play')}
            disabled={!playEnabled}
            doAction={navigateToPage}
          />
        </div>
      </div>
    ) : (
      <Loading />
    );
  }

  return (
    <>
      <SlotMachine content={LandingContent()} />
      {offerId && hasHomeSpotlight ? (
        <SpotlightPopin offerId={offerId} />
      ) : null}
    </>
  );
}

export default Landing;
