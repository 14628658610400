import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from '@reach/router';
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import Loading from '../../components/Loading/Loading';
import './assets/styles.scss';
import {
  getMemberId,
  getOfferId,
  getQuizz,
  getRetailerId,
  postQuizz,
} from '../../../utils/catalinaRequests';
import { onEnter } from '../../../utils/utils';
import RadioButton from '../../components/Form/Radio/RadioButton';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function CollectInfoQuality({ loaded }) {
  let params = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation('message');
  const [loading, setLoading] = useState(false);
  const [quizzData, setQuizzData] = useState();
  const [checked, setChecked] = useState(-1);
  let quizzId = params.quizzId;

  useEffect(() => {
    setLoading(true);

    async function getQuizzData(quizzId) {
      if (!quizzData) {
        try {
          let quizz = await getQuizz(quizzId);
          setQuizzData(quizz);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.error('ERROR :', e);
        }
      }
    }
    getQuizzData(quizzId);
  }, []);

  async function onSubmit() {
    setLoading(true);
    let body = {
      member_id: getMemberId(),
      answer_id: checked,
      retailer_id: getRetailerId(),
      offer_id: getOfferId(),
    };

    let response = await postQuizz(quizzId, body);
    if (response.status === 200) {
      setLoading(false);
      return navigate('/in-store');
    }
    setLoading(false);
  }

  if (!loaded || !quizzData) {
    return <Loading />;
  }

  const Content = () => {
    if (!quizzData) return <Loading />;
    return (
      <div className="collect-info-container">
        <form onSubmit={onSubmit} onKeyUp={onEnter}>
          <div className="subtitle">{quizzData.title}</div>
          <label className="description-quality">{quizzData.description}</label>
          <div className="question-container">
            {quizzData.answers.map((answer, index) => (
              <RadioButton
                key={index}
                title={answer.label}
                checkAction={() => setChecked(index)}
                checked={checked === index}
              />
            ))}
          </div>
          <div className="button-area">
            <Button
              disabled={checked === -1 || loading}
              text={t('general.validate')}
              type="submit"
            />
          </div>
        </form>
      </div>
    );
  };

  return <SlotMachine content={<Content />} />;
}
